<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <div class="page__wrap">
          <sidebar-closet current="3"></sidebar-closet>

          <div class="page__content">
            <div style="width: 100%; text-align: right;">
              <router-link type="button" class="button_bg" to="/armazenar"> Solicitar coleta </router-link>
            </div>
            <div class="tabs__flex">
              <div style="margin-right: 100px;" class="tabs__search">
                <div class="search__form">
                  <input class="form__input" type="search" placeholder="Pesquisar..." v-model="search" />
                  <button class="button search__button" type="submit">
                    <i class="icon icon-search"></i>
                  </button>
                </div>
              </div>
              <div class="tabs__filters">
                <div class="tabs__filters--group">
                  <label class="tabs__filters--label">De</label>
                  <input
                    class="form__input tabs__filters--input"
                    type="date"
                    v-model="startDate"
                  />
                </div>

                <div  class="tabs__filters--group">
                  <label class="tabs__filters--label">Até</label>
                  <input
                    class="form__input tabs__filters--input"
                    type="date"
                    v-model="endDate"
                  />
                </div>
              </div>
            </div>
            <div class="material__list">
              <div v-if="loading">Carregando dados...</div>
              <dir v-else-if="!items.length">
                Nenhuma coleta agendada no momento.
              </dir>

              <div
                v-else
                v-for="(item, index) in filtered"
                :key="index"
                class="material__item"
              >
                <img
                  class="material__item--image"
                  :src="$help.image(item.photo)"
                  alt=""
                />

                <div class="material__item--content">
                  <h4>{{ item.name }}</h4>
                  <p class="green">
                    <i class="fas fa-check-circle"></i>Coleta agendada
                  </p>

                  <div class="material__item--footer">
                    <div class="material__item--fflex">
                      <div class="material__item--date">
                        <p><strong>Dia: </strong>{{ item.colletion_date | date }}</p>
                        <p><strong>Coletor: </strong> {{ item.coletor_name }} </p>
                      </div>
                      <div>
                        <a style="text-decoration: none; width: 110px;" class="material__item--anchor" @click="openObservation(item.order_id)" title="Chat">
                        {{item.count}} Comentários <span v-show="item.unviewed !== null" class="qtd">{{ item.unviewed }}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <modal
        :modal.sync="commentsModal"
        @change-modal="e => (commentsModal = e)"
        :orderId.sync="orderId"
      />
    </section>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import swal from 'sweetalert'

export default {
  data: () => ({
    loading: false,
    search: null,
    startDate: null,
    endDate: null,
    filtered: [],
    orderId: null,
    commentsModal: false
  }),
  components: {
    sidebarCloset: () => import('@/components/sidebar-closet'),
    modal: () => import('@/components/comments.vue')
  },
  computed: {
    ...mapGetters('closet', ['items'])
  },
  methods: {
    ...mapActions('closet', ['fetchItems']),
    openObservation (orderId) {
      this.commentsModal = true
      this.orderId = orderId
    },
    alert (icon, message) {
      swal({
        title: 'Observações',
        icon: icon,
        message,
        button: 'Ok',
        timer: 5000
      })
    }
  },
  async mounted () {
    this.loading = true
    this.fetchItems(2)
    this.loading = false
  },
  filters: {
    date (val) {
      if (!val) return ''
      return val
        .split('-')
        .reverse()
        .join('/')
    }
  },
  watch: {
    search (val) {
      const list = []
      if (val !== '') {
        this.items.forEach(element => {
          if (element.name.toLowerCase().includes(val.toLowerCase()) || element.coletor_name.toLowerCase().includes(val.toLowerCase())) {
            list.push(element)
          }
        })
        this.filtered = list
      } else {
        this.filtered = this.items
      }
    },
    items (val) {
      this.filtered = val
    },
    startDate (val) {
      if (val !== null && this.endDate !== null) {
        this.fetchItems({ status: 2, startDate: val, endDate: this.endDate })
      }
    },
    endDate (val) {
      if (this.startDate !== null && val !== null) {
        this.fetchItems({ status: 2, startDate: this.startDate, endDate: val })
      }
    }
  }
}
</script>

<style scoped>

.qtd{
  background-color: red;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 10px;
  line-height: 20px;
  color: #ffffff;
  font-size: 13px;
  font-weight: bolder;
  margin-top: -20px;
  margin-left: -8px;
}

.button_bg {
    background-color: #16b666;
    padding: 11px;
    border-radius: 33px;
    color: #FFFFFF;
    font-weight: bold;
    margin-right: 0px;
    width: 168px !important;
    text-align: center;
    display: inline-block;
}

</style>
